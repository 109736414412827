<template>
  <div>

    <b-row class="mt-n1 ml-0 mr-0">

      <!-- Left side Icons -->

      <b-col class="pb-2 pt-2" cols="11">
        <div class="row">

          <!-- 01 Total Statement -->

          <div class="d-flex align-items-center mr-3 mb-1">

            <b-avatar rounded variant="light-primary">
              <feather-icon icon="FileIcon" size="18"/>
            </b-avatar>

            <div class="ml-1">
              <h4 class="mb-0 font-weight-bolder "> {{invoiceSummary.total_statements_count}} </h4>
              <small>Total Statements</small>
            </div>
          </div>

          <!-- 02 Total Paid -->

          <div class="d-flex align-items-center mr-3 mb-1">

            <b-avatar rounded variant="light-primary">
              <feather-icon icon="DollarSignIcon" size="18"/>
            </b-avatar>

            <div class="ml-1">
              <h4 class="mb-0 font-weight-bolder"> £{{invoiceSummary.total_paid}} </h4>
              <small>Total Paid</small>
            </div>
          </div>

          <!-- 03 Paid This Month -->

          <div class="d-flex align-items-center mr-3 mb-1">

            <b-avatar rounded variant="light-success">
              <feather-icon icon="CreditCardIcon" size="18"/>
            </b-avatar>

            <div class="ml-1">
              <ul class="list-inline mb-0">
                <li class="list-inline-item">
                  <h4 class="mb-0 font-weight-bolder">£{{invoiceSummary.total_paid_this_month}}</h4>
                </li>
<!--                <li class="list-inline-item">-->
<!--                  <small class="text-success font-weight-bold small">+17%</small>-->
<!--                </li>-->
              </ul>
              <small>Paid This Month</small>
            </div>
          </div>

          <!-- 04 Unpaid Statement -->

          <div class="d-flex align-items-center mr-3 mb-1">

            <b-avatar rounded variant="light-danger">
              <feather-icon icon="AlertCircleIcon" size="18"/>
            </b-avatar>

            <div class="ml-1">
              <h4 class="mb-0 font-weight-bolder">{{invoiceSummary.total_un_paid_statements_count}} </h4>
              <small>Unpaid Statements</small>
            </div>
          </div>

          <!-- 05 Total unpaid -->

          <div class="d-flex align-items-center mr-3 mb-1">
            <b-avatar rounded variant="light-danger">
              <feather-icon icon="CreditCardIcon" size="18"/>
            </b-avatar>

            <div class="ml-1">
              <h4 class="mb-0 font-weight-bolder"> £{{invoiceSummary.total_un_paid}} </h4>
              <small>Total Unpaid</small>
            </div>
          </div>
        </div>
      </b-col>

      <!-- Right side Downloading button -->

      <b-col class="pt-0 pb-2 pt-xl-2 pb-xl-0 ml-n1 " cols="1">
        <b-row>
          <div class="d-flex justify-content-end  mt-2 mt-md-0 pl-0 pl-md-0 ml-auto">

            <b-button
                class="btn-icon bg-white text-primary"
                size="23"
                variant="outline-white">

              <feather-icon
                  icon="DownloadIcon"
                  size="18"
              />
            </b-button>
          </div>
        </b-row>
      </b-col>
    </b-row>

    <b-card class="mp-0">
    <div class="row">

      <!-- Search Bar -->

      <b-col class="mb-1" cols="12" md="4">
        <b-form-group>
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon"/>
            </b-input-group-prepend>
            <b-form-input
                id="icons-search"
                placeholder="Search"
            />
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6"/>

      <!-- Select Date -->


      <b-col class="flex-float-right" cols="12" md="2">
        <b-form-group>
          <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="option1"
              label="title1"
              placeholder="February, 2021"
          />
        </b-form-group>
      </b-col>
    </div>

    <!-- Table -->

    <b-row>

      <b-col cols="12">
        <b-overlay
            :show="tableLoading"
            rounded="sm"
        >

          <b-table
              ref="table"
              :current-page="currentPage"
              :fields="fields"
              :items="getInvoices"
              :per-page="pagination.perPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              class="mobile_table_css"
              hover
              responsive
              @row-clicked="rowClicked"
          >
            <!-- Name $ Avatar -->

            <template #cell(user_name)="data">
              <ul class="list-inline mb-0">
                <li class="list-inline-item">
                  <!--                 <b-avatar :src="require('@/assets/images/portrait/small/avatar-s-5.jpg')" class="pull-up " />-->
                </li>
                <li class="list-inline-item"> {{ data.item.user_name }}</li>
              </ul>
            </template>

            <!-- Paid Unpaid Button -->

            <template #cell(status)="data">
              <span>{{ data.item.status }}</span>
              <!--            <b-dropdown-->
              <!--              id="dropdown-1"-->
              <!--              v-ripple.400="'rgba(255, 255, 255, 0.15)'"-->
              <!--              plain-->
              <!--              style="background-color:rgba(255, 72, 72, 0.1);"-->
              <!--              text="Unpaid "-->
              <!--              variant="outline-danger">-->
              <!--                 <b-dropdown-item>Unpaid</b-dropdown-item>-->
              <!--                 <b-dropdown-item>Paid</b-dropdown-item>-->
              <!--            </b-dropdown>-->
            </template>
            <template #cell(action)="data">
              <b-button
                  v-if="data.item.status ==='outstanding'"
                  v-b-tooltip.hover.top="'Mark as paid'"
                  class="btn-icon bg-white text-primary m-1 "
                  size="23"
                  variant="outline-white"
                  @click="markAsPaid(data.item.id)"
              >
                <feather-icon icon="CheckSquareIcon" size="18"/>
              </b-button>


              <b-button
                  v-if="data.item.status === 'paid'"
                  v-b-tooltip.hover.top="'Mark as unpaid'"
                  class="btn-icon bg-white text-danger m-1"
                  size="23"
                  variant="outline-white"
                  @click="markAsUnPaid(data.item.id)"
              >
                <feather-icon icon="XSquareIcon" size="18" variant="outline-danger"/>
              </b-button>


            </template>
          </b-table>
        </b-overlay>

      </b-col>


      <b-col v-if="pagination.totalRows ===0" class="text-center" cols="12">
        <span>No data for preview</span>
      </b-col>
      <!-- Pagination -->

      <b-col class="d-flex align-items-center justify-content-center justify-content-sm-start" cols="12" sm="6">
        <span v-if="pagination.totalRows !==0" class="text-muted">Showing {{ pagination.from }} to {{ pagination.to }} of {{
            pagination.totalRows
          }} entries</span>
      </b-col>

      <!-- Pagination -->

      <b-col class="d-flex align-items-center justify-content-center justify-content-sm-end" cols="12" sm="6">

        <b-pagination
            v-model="currentPage"
            :per-page="pagination.perPage"
            :total-rows="pagination.totalRows"
            class="mb-0 mt-1 mt-sm-0 "
            first-number
            last-number
            next-class="next-item"
            prev-class="prev-item">

          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18"/>
          </template>

          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18"/>
          </template>

        </b-pagination>

      </b-col>
    </b-row>
    </b-card>
  </div>
</template>

<script>

import {BDropdown, BDropdownItem, BLink, BOverlay, VBTooltip} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCard,
  BTabs,
  BTab,
  BInputGroupPrepend,
  BListGroup,
  BListGroupItem
} from 'bootstrap-vue'
import invoice from '@/apis/modules/invoice'

export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BDropdown,
    BDropdownItem,
    BCard,
    BTabs,
    BTab,
    BInputGroupPrepend,
    BListGroup,
    BListGroupItem,
    BOverlay,
    BLink

  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip
  },
  data () {
    return {
      tableLoading: false,
      invoiceSummary:'',

      option1: [{title1: 'February, 2021'}, {title1: 'January, 2021'}, {title1: 'December, 2020'}, {title1: 'November, 2020'}, {title1: 'Octomber, 2020'}],

      oDataTable: '',
      currentPage: 1,
      pagination: {
        perPage: '',
        pageOptions: '',
        totalRows: '',
        from: '',
        to: ''
      },
      sortBy: '',
      sortDesc: true,
      sortDirection: 'asc',
      // filter: null,
      // filterOn: [],
      // infoModal: {
      //   id: 'info-modal',
      //   title: '',
      //   content: ''
      // },
      fields: [
        {
          key: 'statement_id',
          label: 'STATEMENT ID',
          sortable: false
        },
        {
          key: 'amount',
          label: 'AMOUNT',
          sortable: false
        },
        {
          key: 'user_name',
          label: 'PAID TO',
          sortable: false
        },
        {
          key: 'issue_date',
          label: 'ISSUE DATE',
          sortable: false
        },
        {
          key: 'due_date',
          label: 'DUE DATE',
          sortable: false
        },
        {
          key: 'status',
          label: 'STATUS'
        },
        {
          key: 'action',
          label: 'Action'
        }

      ],
      items: []
    }
  },

  async mounted () {
    await this.getInvoices()
    await this.getInvoiceSummary()
  },
  methods: {
    async rowClicked (item) {
      try {
        await this.$router.push(`/payments/view/${item.id}/invoice`)

      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async getInvoices () {
      try {
        this.tableLoading = true
        const Response = await invoice.getInvoicesList(this.currentPage, 10, this.sortBy, this.sortDesc)
        const dataArray = Response.data.data.map((x) => ({
          id: x.id,
          user_name: x.user.first_name + ' ' + x.user.last_name,
          statement_id: x.id,
          issue_date: x.issue_date,
          due_date: x.due_date,
          amount: x.total_invoice_cost,
          status: x.state

        }))
        const paginationResponse = Response.data.meta
        this.currentPage = paginationResponse.current_page
        this.pagination.totalRows = paginationResponse.total
        this.pagination.perPage = paginationResponse.per_page
        this.pagination.from = paginationResponse.from
        this.pagination.to = paginationResponse.to
        this.tableLoading = false
        return dataArray

      } catch (e) {
        this.convertAndNotifyError(e)
        this.tableLoading = false

      }
    },
    async markAsPaid (id) {
      try {
        this.tableLoading = true
        await invoice.markPaid(id)
        this.showSuccessMessage('Invoice mark paid successful')
        this.tableLoading = false
        this.$refs.table.refresh()


      } catch (e) {
        this.convertAndNotifyError(e)
        this.tableLoading = false

      }

    },
    async markAsUnPaid (id) {
      try {
        this.tableLoading = true
        await invoice.markUnPaid(id)
        this.showSuccessMessage('Invoice mark unpaid successful')
        this.tableLoading = false
        this.$refs.table.refresh()
      } catch (e) {
        this.convertAndNotifyError(e)
        this.tableLoading = false

      }

    },
    async getInvoiceSummary () {
      try {
        this.invoiceSummary = (await invoice.getInvoiceSummaryDetails()).data.data
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    }
  }


}
</script>


<style lang="scss">
div#dropdown-1 .btn-outline-danger {
  border: none !important;
}

@import '@core/scss/vue/libs/vue-select.scss';
</style>
